/* eslint-disable */
import * as THREE from "three";
import {
  useRef,
  Suspense,
  Ref,
  useState,
  useEffect,
  MouseEventHandler,
} from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Vector3 } from "three";
import LinkedInLogo from "./In-White-48.png";
import PSYCHIC from "./mesh/PSYCHIC";
import SIDEKICK from "./mesh/SIDEKICK";

const CustomSpotLight = ({ vec = new Vector3(), ...props }) => {
  const { mousePosition } = props;
  const spotLight = useRef<THREE.SpotLight>();
  const directionalLight = useRef<THREE.DirectionalLight>();
  const viewport = useThree((state) => state.viewport);
  useFrame((state) => {
    spotLight?.current?.target?.position.lerp(
      vec.set(-mousePosition?.x, mousePosition?.y, 0),
      0.1
    );
    spotLight?.current?.target?.updateMatrixWorld();

    directionalLight?.current?.target?.position.lerp(
      vec.set(-mousePosition?.x, mousePosition?.y, 0),
      0.1
    );
    directionalLight?.current?.target?.updateMatrixWorld();
  });
  return (
    <group>
      <spotLight
        ref={spotLight as Ref<THREE.SpotLight>}
        position={[0, 0.5, 4]}
        angle={0.5}
        penumbra={1}
        intensity={0.2}
      />
      <directionalLight
        ref={directionalLight as Ref<THREE.DirectionalLight>}
        intensity={2}
        position={[0, 0.5, 4]}
      />
    </group>
  );
};

const mousePositionFactor = 6;

export default function App() {
  const [isShowing, setIsShowing] = useState(true);

  const [globalCoords, setGlobalCoords] = useState({ x: 0, y: 0 });

  const appRef = useRef<HTMLDivElement>(null);

  /*
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShowing(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, [2000]);
  */

  const handleWindowMouseMove: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!appRef?.current) return;
    setGlobalCoords({
      x:
        ((event.pageX / appRef.current.offsetWidth) * 2 - 1) *
        mousePositionFactor,
      y:
        ((event.pageY / appRef.current.offsetHeight) * 2 - 0.7) *
        mousePositionFactor,
    });
  };

  return (
    <div
      ref={appRef}
      onMouseMove={handleWindowMouseMove}
      className="grid grid-rows-[1fr,auto,auto,1fr] min-h-screen place-items-center gap-0"
    >
      <div className="w-screen h-full bg-[#FF69B4]" />
      <div className="touch-none relative grid grid-flow-col place-items-center aspect-video sm:aspect-auto w-screen sm:h-[384px] bg-[#101010]">
        <div className="absolute top-0 w-screen bottom-2/4 bg-[#FF69B4]" />
        <Canvas
          linear
          gl={{ alpha: true }}
          camera={{ fov: 60, position: [0, 0, 2.2] }}
        >
          <hemisphereLight intensity={0.3} />
          <CustomSpotLight mousePosition={globalCoords} />
          <Suspense fallback={null}>
            <PSYCHIC position={[0, 0, 0.075]} rotation={[Math.PI / 2, 0, 0]} />
            <SIDEKICK position={[0, 0, 0.075]} rotation={[Math.PI / 2, 0, 0]} />
          </Suspense>
        </Canvas>
      </div>
      <div className="grid place-items-center mx-4">
        <div className="animate-fadein3 text-center text-2xl font-bold font-inter text-[#FF69B4]">
          MAKING PLAYFUL EXPERIENCES
        </div>
        <a
          className="animate-fadein4 my-6 text-center text-xl font-bold font-inter text-white hover:scale-105 active:scale-95 transition-transform"
          href="mailto:hello@psychic-sidekick.com"
        >
          hello@psychic-sidekick.com
        </a>
        <a
          href="https://www.linkedin.com/in/linusnordgren"
          target="_blank"
          className="animate-fadein5 my-1 active:opacity-80 hover:scale-110 active:scale-95 transition-transform"
        >
          <img src={LinkedInLogo} />
        </a>
      </div>
      <div className="w-screen h-full bg-[#101010]" />
    </div>
  );
}
