import * as THREE from "three";
import { useRef, useState } from "react";
import { useSpring, animated } from "@react-spring/three";

const ModelPart = (props: any) => {
  // This reference will give us direct access to the THREE.Mesh object
  const ref = useRef<THREE.Mesh>(null!);
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);

  const { pos, rot } = useSpring({
    config: { tension: 50, friction: 15, clamp: false },
    to:  { pos: props.position, rot: props.rotation },
    from: { pos: props.startPosition, rot: props.startRotation },
    delay: props.delay,
  });

  const { scale } = useSpring({
    config: { tension: 600, friction: 15, clamp: false },
    to:  { scale: hovered ? 1.25 : 1 }
  });

  return (
    <animated.mesh
      {...props}
      rotation={rot}
      position={pos}
      scale={scale}
      ref={ref}
      onClick={(event) => click(!clicked)}
      onPointerOver={ (event) => {
        hover(true);
      }}
      onPointerLeave={(event) => {
        hover(false);
      }}
    />
  );
};

export default ModelPart;
