/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import ModelPart from '../ModelPart'

type GLTFResult = GLTF & {
  nodes: {
    PSYCHIC_TEXT_01_P: THREE.Mesh
    PSYCHIC_TEXT_02_S: THREE.Mesh
    PSYCHIC_TEXT_03_Y: THREE.Mesh
    PSYCHIC_TEXT_04_C: THREE.Mesh
    PSYCHIC_TEXT_05_H: THREE.Mesh
    PSYCHIC_TEXT_06_I: THREE.Mesh
    PSYCHIC_TEXT_07_C: THREE.Mesh
  }
  materials: {
    PSYCHIC_Mat: THREE.MeshStandardMaterial
  }
}

export default function Model(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/PSYCHIC.gltf') as GLTFResult
  const startPosition = [-15,0,0];
  return (
    <group {...props} dispose={null}>
      <ModelPart castShadow receiveShadow geometry={nodes.PSYCHIC_TEXT_01_P.geometry} material={materials.PSYCHIC_Mat} position={[-1.45, 0, 0]} startRotation={[0, 0, 0]} delay={600} startPosition={startPosition} />
      <ModelPart castShadow receiveShadow geometry={nodes.PSYCHIC_TEXT_02_S.geometry} material={materials.PSYCHIC_Mat} position={[-0.97, 0, 0]} startRotation={[0, 0, 0]}  delay={500} startPosition={startPosition}/>
      <ModelPart castShadow receiveShadow geometry={nodes.PSYCHIC_TEXT_03_Y.geometry} material={materials.PSYCHIC_Mat} position={[-0.47, 0, 0]} startRotation={[0, 0, 0]}  delay={400} startPosition={startPosition}/>
      <ModelPart castShadow receiveShadow geometry={nodes.PSYCHIC_TEXT_04_C.geometry} material={materials.PSYCHIC_Mat} position={[0, 0, 0]} startRotation={[0, 0, 0]}  delay={300} startPosition={startPosition}/>
      <ModelPart castShadow receiveShadow geometry={nodes.PSYCHIC_TEXT_05_H.geometry} material={materials.PSYCHIC_Mat} position={[0.58, 0, 0]} startRotation={[0, 0, 0]}  delay={200} startPosition={startPosition}/>
      <ModelPart castShadow receiveShadow geometry={nodes.PSYCHIC_TEXT_06_I.geometry} material={materials.PSYCHIC_Mat} position={[1, 0, 0]} startRotation={[0, 0, 0]}  delay={100} startPosition={startPosition}/>
      <ModelPart castShadow receiveShadow geometry={nodes.PSYCHIC_TEXT_07_C.geometry} material={materials.PSYCHIC_Mat} position={[1.38, 0, 0]} startRotation={[0, 0, 0]}  delay={0} startPosition={startPosition}/>
    </group>
  )
}

useGLTF.preload('/PSYCHIC.gltf')
