/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import ModelPart from '../ModelPart'

type GLTFResult = GLTF & {
  nodes: {
    SIDEKICK_TEXT_01_S: THREE.Mesh
    SIDEKICK_TEXT_02_I: THREE.Mesh
    SIDEKICK_TEXT_03_D: THREE.Mesh
    SIDEKICK_TEXT_04_E: THREE.Mesh
    SIDEKICK_TEXT_05_K: THREE.Mesh
    SIDEKICK_TEXT_06_I: THREE.Mesh
    SIDEKICK_TEXT_07_C: THREE.Mesh
    SIDEKICK_TEXT_08_K: THREE.Mesh
  }
  materials: {
    SIDEKICK_Mat: THREE.MeshStandardMaterial
  }
}

export default function Model(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/SIDEKICK.gltf') as GLTFResult
  const startPosition = [15,0,0];
  return (
    <group {...props} dispose={null}>
      <ModelPart receiveShadow geometry={nodes.SIDEKICK_TEXT_01_S.geometry} material={materials.SIDEKICK_Mat} position={[-1.53, 0, 0]} delay={600} startPosition={startPosition}/>
      <ModelPart receiveShadow geometry={nodes.SIDEKICK_TEXT_02_I.geometry} material={materials.SIDEKICK_Mat} position={[-1.17, 0, 0]} delay={700} startPosition={startPosition}/>
      <ModelPart receiveShadow geometry={nodes.SIDEKICK_TEXT_03_D.geometry} material={materials.SIDEKICK_Mat} position={[-0.79, 0, 0]} delay={800} startPosition={startPosition}/>
      <ModelPart receiveShadow geometry={nodes.SIDEKICK_TEXT_04_E.geometry} material={materials.SIDEKICK_Mat} position={[-0.23, 0, 0]} delay={900} startPosition={startPosition}/>
      <ModelPart receiveShadow geometry={nodes.SIDEKICK_TEXT_05_K.geometry} material={materials.SIDEKICK_Mat} position={[0.27, 0, 0]} delay={1000} startPosition={startPosition}/>
      <ModelPart receiveShadow geometry={nodes.SIDEKICK_TEXT_06_I.geometry} material={materials.SIDEKICK_Mat} position={[0.67, 0, 0]} delay={1100} startPosition={startPosition}/>
      <ModelPart receiveShadow geometry={nodes.SIDEKICK_TEXT_07_C.geometry} material={materials.SIDEKICK_Mat} position={[1.07, 0, 0]} delay={1200} startPosition={startPosition}/>
      <ModelPart receiveShadow geometry={nodes.SIDEKICK_TEXT_08_K.geometry} material={materials.SIDEKICK_Mat} position={[1.61, 0, 0]} delay={1300} startPosition={startPosition} />
    </group>
  )
}

useGLTF.preload('/SIDEKICK.gltf')
